<template>
  <article id="wrap">
    
    <!-- <div class="artist-signup"><router-link :to="{name:'signup-artist'}"><span class="black">アーティスト申請はこちらから</span></router-link></div> -->
    <div v-if="flashMessage" class="flash-message">{{ flashMessage }}</div>

      <section class="contents">
        <div class="contentInner pb_80">
          <form @submit.prevent="login">
            <section class="signIn">
              <div class="title-container">
                <img src="@/assets/img/login/suteki-logo.svg" alt="">
              </div>
              <div class="subTitle">すべての推し活へ</div>
              <div class="inputMail">
                <input type="email" v-model="email" placeholder="メールアドレスをご入力ください">
              </div>
              <div class="inputPW" style="display: flex; justify-content: space-between;">
                  <input :type="passwordFieldType" v-model="password" placeholder="パスワードをご入力ください">
              </div>
              <div class="visibility">
                <button type="button" @click="togglePasswordVisibility" style="border: none;">
                  <i :class="{'fa': true, 'fa-eye': passwordFieldType === 'password', 'fa-eye-slash': passwordFieldType !== 'password'}"></i>
                </button>
              </div>
              <div class="bt_form login-button">
                <button type="submit" value="send">
                  LOGIN
                </button>
              </div>
              <div class="">
                <div class="signInText"><router-link :to="{name:'password'}">パスワードを忘れた方はこちら</router-link></div>    
                <div class="bt_register"><router-link :to="{name:'signup'}">新規会員登録</router-link></div>
              </div>
            </section><!--.signIn-->
          </form>
        </div><!--.contentInner-->
      </section><!--.contents-->

      <AppFooter fixed theme=""></AppFooter>

    <div v-if="showModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <p>{{ artistCompleteMessage }}</p>
      </div>
    </div>


  </article>
</template>

<script>
import axios from 'axios';
import AppFooter from '@/components/AppFooter';

export default {
  name: 'Login',
  components: {
    AppFooter
  },
  data() {
    return {
      loading: false,
      email: '',
      password: '',
      passwordFieldType: 'password',
      artistCompleteMessage: '',
      showModal: false,
      error: {
        email: null,
        password: null,
      },
      flashMessage: this.$route.params.flashMessage
    };
  },
  created () {
    this.initialize();
  },
  mounted() {
    if (this.flashMessage) {
      setTimeout(() => {
        this.flashMessage = null;
      }, 2000);
    }
    if (this.$route.query.artistMessage) {
      this.artistCompleteMessage = this.$route.query.artistMessage;
      this.openModal();
  }
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async initialize () {
      // this.$http.get('/sanctum/csrf-cookie').then(response => {
      //   console.log(response);
      // });
      if (this.$store.getters.isLogin) {
        this.$router.push({name: 'mypage'});
      }
    },
    async login () {
      try {
        // APIトークン取得 ここではエラーは独自処理にしたいので素のaxiosを使う
        const response = await axios.post('api/login', {
          email: this.email,
          password: this.password,
        });
        await this.$store.dispatch('signIn', response.data);
        // this.$swal('ログイン', 'ログインしました', 'success').then(() => {
        //   this.$router.push({name: 'myPage'});
        // });
        this.$router.push({name:'home'});
      } catch (e) {
        this.$http.interceptors.response.use(response => {
              return response;
            },
            error => {
              return Promise.reject(error);
            },);
        this.$toasted.error('存在しないアカウントです', {
          position: 'top-center',
          duration: 4000,
          fullWidth: true
        });
      }
    },

    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
  }
}
</script>

<style scoped>
#wrap {
  overflow-y: auto;
}
.contents {
  /* margin-bottom: 200px; */
}
.contentInner {
  margin-bottom: 400px;
  margin-top: 0;
}

.artist-signup {
  position: fixed;
  top: 20px; /* 上部からの距離を調整します */
  left: 50%; /* 左端から50%の位置（つまり中央）に配置します */
  transform: translateX(-50%); 
}
.black {
  color: black;
}
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.flash-message {
  position: fixed;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  border-radius: 5px;
}

/* autofillにより勝手にスタイルが適用されるのを防ぐ */
@keyframes onAutoFillStart { from {} to {}}
input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s,color 50000s ease-in-out 0s;
}

@media screen and (max-width: 767px) {
  .visibility {
        position: absolute;
        top: 220px;
        right: 7px;
  }
}
@media screen and (min-width: 768px) {
  .visibility {
      position: absolute;
      top: 370px;
      right: 2px;
  }
}

</style>